import { createRouter, createWebHashHistory } from 'vue-router'
import { checkToken } from '@/services/appService'
import { useUserProfileStore } from '@/stores/userProfile'
import { useCurrencyStore } from '@/stores/currencyStore'

import DefaultLayout from '@/layouts/DefaultLayout.vue'
import page404 from '@/views/pages/Page404.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login.vue'),
  },
  // will match everything and put it under `$route.params.pathMatch`
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: page404 },
  {
    path: '/500',
    name: 'page500',
    component: () => import('@/views/pages/Page500.vue'),
  },
  // all settings
  {
    path: '/settings',
    name: 'settings',
    component: DefaultLayout,
    redirect: '/settings/global',
    children: [
      {
        path: 'global',
        name: 'appSettings',
        component: () => import('@/views/settings/Settings.vue'),
      },
    ],
  },
  // User profile
  {
    path: '/user',
    name: 'user',
    component: DefaultLayout,
    children: [
      {
        path: ':id',
        name: 'userProfile',
        component: () => import('@/views/user/UserProfile.vue'),
      },
      // User settings
      {
        path: 'me',
        name: 'myProfile',
        component: () => import('@/views/user/UserProfile.vue'),
      },
    ],
  },
  // Workflows
  {
    path: '/workflows',
    name: 'workflows',
    component: DefaultLayout,
    redirect: '/workflows/list',
    children: [
      {
        path: 'list',
        name: 'workflowsList',
        component: () => import('@/views/workflows/WorkflowModels.vue'),
      },
      {
        path: 'view/:id',
        name: 'workflowsView',
        component: () => import('@/views/workflows/WorkflowView.vue'),
      },
    ],
  },
  // Offers
  {
    path: '/offers',
    name: 'offers',
    redirect: '/offers/list',
    component: DefaultLayout,
    children: [
      {
        path: 'list',
        name: 'offersList',
        component: () => import('@/views/offer/OfferList.vue'),
      },
      {
        path: 'view/:id',
        name: 'offerView',
        component: () => import('@/views/offer/OfferView.vue'),
      },
      {
        path: 'creation/:feature',
        name: 'offerCreation',
        component: () => import('@/views/offer/OfferCreation.vue'),
      },
    ],
  },
  // IAR
  {
    path: '/iar',
    name: 'iar',
    component: DefaultLayout,
    redirect: '/iar/list',
    children: [
      {
        path: '/iar/list',
        name: 'iarList',
        component: () => import('@/views/iaf/Listing.vue'),
      },
      {
        path: '/iaf/creation',
        name: 'iarCreation',
        component: () => import('@/views/iaf/Creation.vue'),
      },
    ],
  },
  // Dashboard
  {
    path: '/',
    name: 'home',
    component: DefaultLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
    ],
  },
  // Vehicles
  {
    path: '/vehicles',
    name: 'vehicles',
    component: DefaultLayout,
    redirect: '/vehicles/list',
    children: [
      {
        path: '/vehicles/list',
        name: 'vehicleList',
        component: () => import('@/views/Vehicles.vue'),
      },
    ],
  },
  // clients
  {
    path: '/clients',
    name: 'clients',
    component: DefaultLayout,
    children: [
      {
        path: '/clients/list',
        name: 'clientsList',
        component: () => import('@/views/clients/Clients.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

/**
 * Change for canExtendToken
 */
router.beforeEach(async (to) => {
  const isAuthenticated = await checkToken()
  if (!isAuthenticated && to.name !== 'Login') {
    // window.location.href = '/?#/login' // This is not following the router best practices, but the redirect miss a '?' before the '#'
    return { name: 'Login' }
  }

  if (isAuthenticated) {
    // Rebuild store - useful when reload page
    const userStore = useUserProfileStore()
    userStore.restoreProfile()

    // Rebuild Currencies and org stores
    const currenciesStore = useCurrencyStore()
    currenciesStore.fecthCurrencies()
  }
})

export default router

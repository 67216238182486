export default [
  {
    component: 'CNavItem',
    name: 'navMenu.offers',
    to: '#/offers',
    icon: 'cil-list-rich',
  },
  {
    component: 'CNavItem',
    name: 'navMenu.workflows',
    to: '#/workflows',
    icon: 'cil-check-circle',
  },
]

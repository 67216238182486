import { ref } from 'vue'
import axios from 'axios'
import { getAuthHeader } from '@/services/appService'

export function useFetch(url) {
  const data = ref(null)
  const error = ref(null)
  const loading = ref(true)

  const fetchData = async () => {
    loading.value = true
    try {
      const response = await axios.get(url, {
        headers: getAuthHeader(),
      })
      data.value = response.data
    } catch (err) {
      error.value = err
    } finally {
      loading.value = false
    }
  }

  fetchData()

  return { data, error, loading, fetchData }
}

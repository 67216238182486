import { defineStore } from 'pinia'
import { useFetch } from '@/services/apiService'

const API_URL = import.meta.env.VITE_APP_BACKEND_URL

export const useCurrencyStore = defineStore('currency', {
  state: () => ({
    currencies: {},
    fetched: false,
  }),
  actions: {
    /**
     * Fetch currencies values from backend
     * and store it in order to avoid multiple calls
     * The currencies values won't be that dynamic.
     */
    async fecthCurrencies() {
      if (!this.fetched) {
        const { data } = useFetch(`${API_URL}/currencies`)
        this.currencies = data
        this.fetched = true
      }
    },
    /**
     * Maybe it's a good idea to move this method to a service ¯\_(ツ)_/¯
     * The price history is related to Euro.
     */
    convertAmount(amount, fromId, toId) {
      const EuroAmount = amount / this.currencies[fromId].value
      return (EuroAmount * this.currencies[toId].value).toFixed(2)
    },
  },
  getters: {
    getCurrencies: (state) => state.currencies,
    getCurrencyById: (state) => {
      return (id) => state.currencies[id]
    },
  },
})
